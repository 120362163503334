@charset "UTF-8";
/*
* SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
*
* SPDX-License-Identifier: AGPL-3.0-only
*/
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/public-sans/wght.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/public-sans/wght-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/lora/wght.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/lora/wght-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/jetbrains-mono/wght.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/jetbrains-mono/wght-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/atkinson-hyperlegible/400.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/atkinson-hyperlegible/400-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/atkinson-hyperlegible/700.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/atkinson-hyperlegible/700-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/inter/wght.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/inter/wght-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/literata/wght.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource-variable/literata/wght-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/ui/fonts/luciole/index.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/merriweather/400.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/merriweather/400-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/merriweather/700.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/merriweather/700-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/ibm-plex-serif/400.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/ibm-plex-serif/400-italic.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/ibm-plex-serif/700.css";
@import "/home/forgejo/.cache/act/af762d6fba032269/hostexecutor/web/node_modules/@fontsource/ibm-plex-serif/700-italic.css";
@import "tailwindcss/base.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";
@layer base {
  :root {
    --color-white: rgb-values(#fff);
    --color-black: rgb-values(#000);
    --color-gray-light: rgb-values(#fff);
    --color-gray-dark: rgb-values(#000);
    --color-gray: color-scale(#968273 inoutsine gamma 1.5);
    --color-blue: color-scale(#0da6c9 inoutsine gamma 1.2);
    --color-red: color-scale(#ef4444 inoutsine gamma 1.6);
    --color-green: color-scale(#84cc16 inoutsine);
    --color-yellow: color-scale(#f9b807 inoutsine gamma 1.7);
    --color-app-bg: var(--color-gray-50);
    --color-app-fg: var(--color-gray-900);
    --color-primary: var(--color-blue-600);
    --color-primary-light: var(--color-blue-400);
    --color-primary-dark: var(--color-blue-700);
    --color-shadow: var(--color-gray-700);
    --default-shadow: rgb(var(--color-shadow) / 0.2);
    --color-btn-default: var(--color-gray-700);
    --color-btn-default-hover: var(--color-gray-800);
    --color-btn-default-text: var(--color-white);
    --color-btn-primary: var(--color-primary);
    --color-btn-primary-hover: var(--color-primary-dark);
    --color-btn-primary-text: var(--color-white);
    --color-btn-danger: var(--color-red-600);
    --color-btn-danger-hover: var(--color-red-800);
    --color-btn-danger-text: var(--color-white);
    --color-hl-yellow: var(--color-yellow-200);
    --color-hl-yellow-dark: var(--color-yellow-500);
    --color-hl-red: var(--color-red-200);
    --color-hl-red-dark: var(--color-red-500);
    --color-hl-blue: var(--color-blue-100);
    --color-hl-blue-dark: var(--color-blue-500);
    --color-hl-green: var(--color-green-200);
    --color-hl-green-dark: var(--color-green-500);
  }
  :root.dark {
    --color-app-bg: var(--color-gray-50);
    --color-app-fg: var(--color-gray-950);
    --color-gray-light: rgb-values(#000);
    --color-gray-dark: rgb-values(#fff);
    --color-gray: color-scale(#6a7e84 reversed inoutsine to #161a1b gamma 1.2);
    --color-blue: color-scale(#0cabcf reversed inoutsine gamma 1.6);
    --color-red: color-scale(#ef4444 reversed inoutsine gamma 1.1);
    --color-green: color-scale(#84cc16 reversed inoutsine gamma 1.1);
    --color-yellow: color-scale(#f9b807 reversed inoutsine gamma 1.1);
    --color-primary: var(--color-blue-600);
    --color-primary-light: var(--color-blue-400);
    --color-primary-dark: var(--color-blue-700);
    --color-shadow: var(--color-black);
    --default-shadow: rgb(var(--color-shadow) / 0.6);
    --color-btn-default: var(--color-gray-400);
    --color-btn-default-hover: var(--color-gray-300);
    --color-btn-primary: var(--color-blue-400);
    --color-btn-primary-hover: var(--color-blue-300);
    --color-btn-danger: var(--color-red-400);
    --color-btn-danger-hover: var(--color-red-300);
    --color-hl-yellow: var(--color-yellow-200);
    --color-hl-yellow-dark: var(--color-yellow-300);
    --color-hl-red: var(--color-red-200);
    --color-hl-red-dark: var(--color-red-300);
    --color-hl-blue: var(--color-blue-200);
    --color-hl-blue-dark: var(--color-blue-300);
    --color-hl-green: var(--color-green-200);
    --color-hl-green-dark: var(--color-green-500);
  }
}
body.js input::-ms-reveal,
body.js input::-ms-clear {
  display: none;
}

@layer base {
  svg {
    fill: currentColor;
  }
  :focus-visible {
    @apply outline-none;
    @apply ring-2 ring-offset-2;
    @apply ring-yellow-500 bg-yellow-100 ring-offset-yellow-100;
  }
  :is(.dark :focus-visible) {
    @apply ring-yellow-400 bg-yellow-100 ring-offset-yellow-100;
  }
  ::selection {
    @apply bg-blue-700 text-gray-light;
  }
  button * {
    pointer-events: none;
  }
  details > summary {
    @apply cursor-pointer;
  }
  details > summary:not(.with-marker) {
    @apply list-none;
  }
  details > summary:not(.with-marker)::-webkit-details-marker {
    display: none;
  }
  progress {
    -webkit-appearance: none;
    appearance: none;
    @apply rounded-full bg-gray-300;
  }
  progress::-webkit-progress-bar {
    @apply rounded-full bg-gray-300;
  }
  progress::-moz-progress-bar {
    @apply rounded-full bg-primary;
  }
  progress::-webkit-progress-value {
    @apply rounded-full bg-primary;
  }
}
@layer base {
  * {
    scrollbar-color: rgb(var(--color-gray-400)) rgb(var(--color-gray-100));
    scrollbar-width: auto;
  }
  *::-webkit-scrollbar {
    width: 4px;
  }
  *::-webkit-scrollbar-track {
    width: 4px;
    background: rgb(var(--color-gray-100));
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgb(var(--color-gray-400));
    width: 2px;
    border-radius: 3px;
  }
}
@layer base {
  @media print {
    html {
      font-size: 8pt;
    }
  }
}
@layer base {
  /* autoprefixer: off */
  @supports (contain: paint) and (not (-moz-appearance: none)) {
    input[type=password]:not(:placeholder-shown) {
      font-family: pass, sans-serif;
      letter-spacing: 1px;
    }
  }
}
@layer components {
  .link {
    @apply text-primary hf:text-primary-dark;
  }
  @media print {
    .link {
      @apply text-black underline font-semibold;
    }
  }
  .outline-std {
    @apply outline-1 outline-dotted outline-current;
  }
  .svgicon {
    display: inline;
    line-height: 1;
    position: relative;
    height: 1em;
    top: -0.09em;
  }
  .svgicon svg {
    height: 1em;
  }
  .title {
    @apply mb-4 font-semibold text-gray-700;
  }
  .wrap-anywhere {
    overflow-wrap: anywhere;
  }
}
@layer components {
  rd-annotation,
  .rd-annotation {
    background-color: rgb(var(--annotation-bg)/var(--tw-bg-opacity, 1));
    border-color: var(--annotation-border);
  }
  rd-annotation[data-annotation-color=yellow],
  .rd-annotation[data-annotation-color=yellow] {
    --annotation-bg: var(--color-hl-yellow);
    --annotation-border: rgb(var(--color-hl-yellow-dark));
  }
  rd-annotation[data-annotation-color=red],
  .rd-annotation[data-annotation-color=red] {
    --annotation-bg: var(--color-hl-red);
    --annotation-border: rgb(var(--color-hl-red-dark));
  }
  rd-annotation[data-annotation-color=blue],
  .rd-annotation[data-annotation-color=blue] {
    --annotation-bg: var(--color-hl-blue);
    --annotation-border: rgb(var(--color-hl-blue-dark));
  }
  rd-annotation[data-annotation-color=green],
  .rd-annotation[data-annotation-color=green] {
    --annotation-bg: var(--color-hl-green);
    --annotation-border: rgb(var(--color-hl-green-dark));
  }
  button.rd-annotation {
    --annotation-border: rgb(var(--color-white) / 0.8);
  }
  button.rd-annotation[value=yellow] {
    --annotation-bg: var(--color-yellow-700);
  }
  button.rd-annotation[value=red] {
    --annotation-bg: var(--color-red-700);
  }
  button.rd-annotation[value=blue] {
    --annotation-bg: var(--color-blue-700);
  }
  button.rd-annotation[value=green] {
    --annotation-bg: var(--color-green-700);
  }
}
@layer components {
  :root {
    --btn-color: var(--color-gray-300);
    --btn-color-hover: var(--color-gray-300);
    --btn-color-invert: var(--color-gray-dark);
    --btn-color-border: var(--color-gray-400);
  }
  .btn {
    @apply transition-colors duration-200;
    @apply inline-block leading-tight whitespace-nowrap cursor-pointer;
    @apply rounded;
    padding: theme("spacing.2");
    padding: calc(theme("spacing.2") + 1px) theme("spacing.2");
    color: rgb(var(--btn-color-invert));
    background-color: rgb(var(--btn-color));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2) 1px, transparent 2px);
  }
  .btn:hover, .btn:focus-visible {
    background-color: rgb(var(--btn-color-hover));
  }
  .btn:active {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 1px, transparent 2px);
    background-color: rgb(var(--btn-color));
  }
  .btn-outlined {
    @apply btn;
    @apply p-2 bg-transparent;
    --btn-color: var(--color-btn-default);
    --btn-color-hover: var(--color-btn-default);
    --btn-color-invert: var(--color-gray-light);
    border: 1px solid rgb(var(--btn-color-border));
    background-image: none;
    color: rgb(var(--btn-color));
  }
  .btn-outlined:hover, .btn-outlined:focus-visible {
    color: rgb(var(--btn-color-invert));
    border-color: rgb(var(--btn-color-hover));
  }
  .btn-outlined:active {
    color: rgb(var(--btn-color));
    border-color: rgb(var(--btn-color));
    background: transparent;
  }
  .btn-group {
    @apply inline-flex rounded overflow-hidden;
    border: 1px solid rgb(var(--btn-color-border));
  }
  .btn-group .btn,
  .btn-group .btn-outlined {
    border: none;
    border-radius: 0;
  }
  .btn-default {
    --btn-color: var(--color-btn-default);
    --btn-color-border: var(--btn-color);
    --btn-color-hover: var(--color-btn-default-hover);
    --btn-color-invert: var(--color-btn-default-text);
  }
  .btn-primary {
    --btn-color: var(--color-btn-primary);
    --btn-color-border: var(--btn-color);
    --btn-color-hover: var(--color-btn-primary-hover);
    --btn-color-invert: var(--color-btn-primary-text);
  }
  .btn-danger {
    --btn-color: var(--color-btn-danger);
    --btn-color-border: var(--btn-color);
    --btn-color-hover: var(--color-btn-danger-hover);
    --btn-color-invert: var(--color-btn-danger-text);
  }
  .btn-block {
    @apply flex gap-2 mt-8 p-3 bg-gray-100/60 border border-gray-200/60 rounded;
  }
}
.bookmark-list-container {
  @apply flex gap-4;
}

@container layout-content (width <= 500px) {
  .bookmark-list-container {
    @apply flex-col;
  }
}
.bookmark-list-container .bookmark-list {
  @apply flex-grow;
}

.bookmark-list-container .bookmark-filters {
  @apply bg-gradient-to-b from-gray-100 to-10% to-app-bg;
  @apply p-2;
}

@container layout-content (width > 500px) {
  .bookmark-list-container .bookmark-filters {
    @apply border-l border-l-gray-200;
    @apply shrink-0;
    @apply order-last;
    width: calc(theme("spacing.col-5") - 1rem);
  }
}
@media print {
  .bookmark-list-container .bookmark-filters {
    @apply hidden;
  }
}
.bookmark-list-container .bookmark-filters--actions {
  @apply sticky bottom-0;
  @apply -mx-2 my-4 px-2 py-4 border-t;
  @apply bg-gradient-to-b from-gray-100 to-app-bg;
  @apply flex flex-wrap gap-2;
}

.bookmark-list-container .bookmark-filters--actions > * {
  @apply flex-grow;
}

.bookmark-list-container details.bookmark-filters > summary {
  @apply hidden;
}

body.js .bookmark-list-container details.bookmark-filters:not([open]) {
  display: none;
}

body.no-js .bookmark-list-container details.bookmark-filters:not([open]) {
  @apply relative w-auto;
  @apply border-none p-0;
}

.bookmark-list {
  container-type: inline-size;
  container-name: bookmark-list;
}

.bookmark-card:hover, .bookmark-card:focus-within {
  @apply shadow;
}

.bookmark-card:hover .bookmark-card--title a, .bookmark-card:focus-within .bookmark-card--title a {
  @apply link;
}

.bookmark-card:hover .bookmark-card--progress, .bookmark-card:focus-within .bookmark-card--progress {
  @apply filter-none opacity-100;
}

.bookmark-card:hover .bookmark-card--actions form button,
.bookmark-card:hover .bookmark-card--actions form a, .bookmark-card:focus-within .bookmark-card--actions form button,
.bookmark-card:focus-within .bookmark-card--actions form a {
  @apply text-btn-primary hf:text-btn-primary-hover;
}

.bookmark-card:hover .bookmark-card--actions form button[name=is_deleted],
.bookmark-card:hover .bookmark-card--actions form a[name=is_deleted], .bookmark-card:focus-within .bookmark-card--actions form button[name=is_deleted],
.bookmark-card:focus-within .bookmark-card--actions form a[name=is_deleted] {
  @apply text-btn-danger hf:text-btn-danger-hover;
}

.bookmark-card--img {
  grid-area: img;
}

.bookmark-card--ico {
  grid-area: ico;
}

.bookmark-card--progress {
  grid-area: progress;
  @apply opacity-70 stroke-gray-600 fill-gray-200/50;
}

.bookmark-card--progress > svg {
  @apply w-6 h-6 stroke-inherit fill-inherit;
}

.bookmark-card--title {
  grid-area: title;
  text-wrap: balance;
}

.bookmark-card--title a {
  @apply overflow-hidden text-ellipsis;
  @apply block font-semibold text-left;
}

.bookmark-card--spinner {
  grid-area: spinner;
  @apply p-2;
  @apply flex gap-2 items-center;
  @apply text-center;
}

.bookmark-card--spinner > .svgicon,
.bookmark-card--spinner .svgicon svg {
  @apply w-8 h-8;
}

.bookmark-card--spinner > p {
  @apply overflow-hidden text-ellipsis;
  overflow-wrap: anywhere;
}

.bookmark-card--labels {
  grid-area: labels;
}

.bookmark-card--labels > ul {
  @apply inline-flex flex-wrap justify-start gap-x-1 gap-y-3 my-1;
}

.bookmark-card--labels > ul > li > a {
  @apply text-sm bg-gray-100 leading-none py-1 px-1.5 border rounded-md;
  @apply hf:bg-yellow-100;
}

.bookmark-card--meta {
  grid-area: meta;
}

.bookmark-card--actions {
  grid-area: actions;
}

.bookmark-card--actions form {
  @apply text-gray-200;
}

.bookmark-card--actions button,
.bookmark-card--actions a {
  @apply fill-current hf:text-btn-primary;
}

.bookmark-card--actions button[name=is_marked][value="0"], .bookmark-card--actions button[name=is_archived][value="0"],
.bookmark-card--actions a[name=is_marked][value="0"],
.bookmark-card--actions a[name=is_archived][value="0"] {
  @apply text-primary;
}

.bookmark-card--actions button[name=is_deleted],
.bookmark-card--actions a[name=is_deleted] {
  @apply hf:text-btn-danger;
}

.bookmark-card--actions .svgicon svg {
  width: 18px;
  height: 18px;
}

@screen touch {
  .bookmark-card--actions {
    @apply mt-1;
  }
  .bookmark-card--actions .svgicon svg {
    @apply w-6 h-6;
  }
}
.bookmark-card--deleted {
  grid-area: deleted;
  @apply flex flex-wrap gap-2 items-center;
}

.bookmark-card--deleted > span {
  @apply flex-grow text-red-700 text-xs font-semibold;
}

.bookmark-card--deleted form {
  @apply flex flex-grow;
}

.bookmark-card--deleted button {
  @apply btn btn-primary whitespace-nowrap text-sm py-1 w-full;
}

.bookmark-card--deleted button svg {
  @apply fill-current;
}

.bookmark-card[data-deleted=true] .bookmark-card--img,
.bookmark-card[data-deleted=true] .bookmark-card--ico,
.bookmark-card[data-deleted=true] .bookmark-card--title,
.bookmark-card[data-deleted=true] .bookmark-card--meta,
.bookmark-card[data-deleted=true] .bookmark-card--labels {
  @apply opacity-50;
}

.bookmark-list .bookmark-list--grid {
  --col-number: 1;
  grid-template-columns: repeat(var(--col-number), minmax(0, 1fr));
}

@container bookmark-list (width > 500px) {
  .bookmark-list .bookmark-list--grid {
    --col-number: 2;
  }
}
@container bookmark-list (width > 610px) {
  .bookmark-list .bookmark-list--grid {
    --col-number: 3;
  }
}
@container bookmark-list (width > 880px) {
  .bookmark-list .bookmark-list--grid {
    --col-number: 4;
  }
}
@container bookmark-list (width >= 1240px) {
  .bookmark-list .bookmark-list--grid {
    --col-number: 6;
  }
}
.bookmark-list--grid {
  @apply grid gap-4;
}

.bookmark-list--grid .bookmark-card {
  display: grid;
  position: relative;
  grid-template-areas: "img img" "spinner spinner" "title title" "ico meta" "labels labels" "deleted deleted" "actions actions";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto auto auto 1fr;
  height: 100%;
  @apply overflow-hidden rounded;
}

.bookmark-list--grid .bookmark-card > .svgicon {
  @apply absolute z-10 top-1 left-1 flex items-center justify-center;
  @apply w-6 h-6 rounded-full;
  @apply bg-black bg-opacity-25 text-white;
}

.bookmark-list--grid .bookmark-card--img {
  @apply block overflow-hidden;
}

.bookmark-list--grid .bookmark-card--img > img {
  @apply w-full aspect-video object-cover object-center filter;
}

.bookmark-list--grid .bookmark-card--ico {
  @apply self-baseline;
  @apply pl-2;
}

.bookmark-list--grid .bookmark-card--ico > img,
.bookmark-list--grid .bookmark-card--ico .svgicon {
  @apply block w-4 h-auto;
  @apply filter grayscale opacity-50;
}

.bookmark-list--grid .bookmark-card--title {
  @apply p-2;
}

.bookmark-list--grid .bookmark-card--spinner {
  @apply flex-col;
}

.bookmark-list--grid .bookmark-card--meta {
  @apply self-center;
  @apply px-2 overflow-x-clip overflow-y-hidden;
  @apply flex items-baseline gap-2;
  @apply text-xs text-gray-600;
  line-height: 0;
}

.bookmark-list--grid .bookmark-card--meta > strong {
  @apply leading-none overflow-hidden text-ellipsis;
}

.bookmark-list--grid .bookmark-card--labels {
  @apply px-2 pt-2;
}

.bookmark-list--grid .bookmark-card--actions {
  @apply self-end;
  @apply p-2;
  @apply flex items-end gap-x-3;
}

.bookmark-list--grid .bookmark-card--actions form {
  @apply inline-flex gap-x-2;
}

.bookmark-list--grid .bookmark-card--actions form:nth-of-type(1) {
  @apply flex-grow;
}

@screen touch {
  .bookmark-list--grid .bookmark-card--actions form {
    @apply gap-x-4;
  }
}
.bookmark-list--grid .bookmark-card--deleted {
  @apply p-2 place-self-end;
}

.bookmark-list--grid .bookmark-card:hover, .bookmark-list--grid .bookmark-card:focus-within {
  @apply shadow-md;
}

.bookmark-list--grid .bookmark-card:hover .bookmark-card--img > img, .bookmark-list--grid .bookmark-card:focus-within .bookmark-card--img > img {
  @apply contrast-105 brightness-95;
}

.bookmark-list--grid .bookmark-card:hover .bookmark-card--ico > img,
.bookmark-list--grid .bookmark-card:hover .bookmark-card--ico .svgicon, .bookmark-list--grid .bookmark-card:focus-within .bookmark-card--ico > img,
.bookmark-list--grid .bookmark-card:focus-within .bookmark-card--ico .svgicon {
  @apply filter-none opacity-100;
}

.bookmark-list--grid .bookmark-card--progress {
  @apply absolute z-10 top-1 right-1 fill-black/40 stroke-white opacity-75;
}

@container bookmark-list (width < 500px) and (width > 300px) {
  .bookmark-list--grid .bookmark-card {
    grid-template-areas: "img spinner title title" "img spinner ico meta" "img spinner labels labels" "img spinner deleted deleted" "img spinner actions actions";
    grid-template-columns: auto auto auto 1fr;
    @apply -ml-2;
  }
  .bookmark-list--grid .bookmark-card > .svgicon {
    @apply left-3 top-3 w-5 h-5;
  }
  .bookmark-list--grid .bookmark-card > .svgicon > svg {
    @apply w-4 h-4;
  }
  .bookmark-list--grid .bookmark-card--progress {
    @apply left-16 top-3 w-5;
  }
  .bookmark-list--grid .bookmark-card--progress > svg {
    @apply w-5 h-5;
  }
  .bookmark-list--grid .bookmark-card--img {
    @apply overflow-hidden w-20 ml-2 my-2;
  }
  .bookmark-list--grid .bookmark-card--img > img {
    @apply aspect-square rounded-md;
  }
  .bookmark-list--grid .bookmark-card--meta > strong {
    @apply flex-grow-0;
  }
  .bookmark-list--grid .bookmark-card--spinner {
    @apply flex-row text-left;
  }
  .bookmark-list--grid .bookmark-card--title {
    text-wrap: auto;
  }
}
.bookmark-list--compact .bookmark-card {
  display: grid;
  position: relative;
  grid-template-areas: "ico title title title actions deleted" "ico progress meta labels actions deleted" "ico . spinner spinner . .";
  grid-template-columns: auto auto auto 1fr auto auto;
  @apply mb-2 -mx-2 pb-2;
  @apply rounded;
}

.bookmark-list--compact .bookmark-card > .svgicon {
  @apply absolute z-10 top-6 left-4 flex items-center justify-center;
  @apply w-5 h-5 rounded-full;
  @apply bg-black bg-opacity-40 text-white;
}

.bookmark-list--compact .bookmark-card > .svgicon svg {
  @apply w-3.5 h-3.5;
}

.bookmark-list--compact .bookmark-card--progress {
  @apply pt-1 pr-2 self-center;
}

.bookmark-list--compact .bookmark-card--progress > svg {
  @apply w-5 h-5;
}

.bookmark-list--compact .bookmark-card--img {
  @apply hidden;
}

.bookmark-list--compact .bookmark-card--ico {
  @apply self-baseline;
  @apply py-2 pr-3 pl-2;
}

.bookmark-list--compact .bookmark-card--ico > img,
.bookmark-list--compact .bookmark-card--ico .svgicon,
.bookmark-list--compact .bookmark-card--ico .svgicon svg {
  @apply w-6 h-6;
}

.bookmark-list--compact .bookmark-card--title {
  @apply pt-2 pr-2;
}

.bookmark-list--compact .bookmark-card--meta {
  @apply self-baseline;
  @apply text-sm text-gray-600;
  @apply pt-1 pr-2;
}

.bookmark-list--compact .bookmark-card--meta > strong {
  @apply overflow-hidden text-ellipsis;
}

.bookmark-list--compact .bookmark-card--labels {
  @apply self-baseline;
  @apply pt-1;
}

.bookmark-list--compact .bookmark-card--actions {
  @apply flex items-end gap-x-3;
  @apply pt-2 pr-2;
  align-self: start;
}

.bookmark-list--compact .bookmark-card--actions form {
  @apply inline-flex gap-x-3;
}

.bookmark-list--compact .bookmark-card--actions form:nth-of-type(1) {
  @apply flex-grow;
}

.bookmark-list--compact .bookmark-card--deleted {
  @apply pt-2 pr-2;
  align-self: start;
}

@container bookmark-list (width <= 500px) {
  .bookmark-list--compact .bookmark-card {
    grid-template-areas: "ico spinner title title title" "ico spinner progress meta meta" "ico spinner labels labels labels" "ico spinner actions actions actions" "ico spinner deleted deleted deleted";
    grid-template-columns: auto auto auto 1fr auto;
  }
  .bookmark-list--compact .bookmark-card--spinner {
    @apply text-left;
  }
}
@layer components {
  .breadcrumbs {
    @apply mb-5 -mt-4;
    @apply bg-gray-100;
    @apply p-2 rounded-md;
  }
  .breadcrumbs ol > li {
    @apply inline font-semibold;
  }
  .breadcrumbs ol > li a {
    @apply link;
  }
  .breadcrumbs ol > li + li::before {
    @apply inline-block;
    @apply mx-2;
    @apply h-3;
    border-right: 0.1em solid rgb(var(--color-gray-700));
    transform: rotate(15deg);
    content: "";
  }
}
@layer components {
  .form-input,
  .form-textarea,
  .form-select {
    appearance: none;
    @apply bg-gray-light text-gray-dark focus-visible:bg-gray-light;
    @apply border border-gray-400 rounded;
    @apply p-2 text-base leading-tight;
  }
  .form-input > input,
  .form-textarea > input,
  .form-select > input {
    background-color: inherit;
  }
  .form-input:hover,
  .form-textarea:hover,
  .form-select:hover {
    @apply border-gray-600;
  }
  .form-input:focus-visible, .form-input:focus-within,
  .form-textarea:focus-visible,
  .form-textarea:focus-within,
  .form-select:focus-visible,
  .form-select:focus-within {
    outline: none;
    outline-offset: 0px;
    @apply ring-primary;
    border-color: var(--tw-ring-color);
    box-shadow: 0 0 3px 0 var(--tw-ring-color);
  }
  .form-input::placeholder,
  .form-textarea::placeholder {
    @apply text-gray-500;
    opacity: 1;
  }
  .form-input::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }
  .form-select {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 8l4 4 4-4"/></svg>');
    background-position: right theme("spacing.2") center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    @apply pr-10;
    color-adjust: exact;
  }
  .form-select[multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    @apply pr-3;
    color-adjust: unset;
  }
  .form-checkbox,
  .form-radio {
    appearance: none;
    padding: 0;
    color-adjust: exact;
    display: inline-block;
    vertical-align: text-top;
    background-origin: border-box;
    user-select: none;
    flex-shrink: 0;
    @apply h-4 w-4;
  }
  .form-checkbox, .form-checkbox:checked, .form-checkbox:indeterminate,
  .form-radio,
  .form-radio:checked,
  .form-radio:indeterminate {
    @apply text-gray-light bg-gray-light border border-gray-400;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .form-checkbox:hover, .form-checkbox:focus-visible,
  .form-radio:hover,
  .form-radio:focus-visible {
    @apply border-gray-600;
  }
  .form-checkbox:focus-visible,
  .form-radio:focus-visible {
    outline: none;
    outline-offset: 0px;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    @apply ring-offset-gray-light ring-offset-2 ring-primary;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
      var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width))
      var(--tw-ring-color);
  }
  .form-checkbox {
    border-radius: 0;
  }
  .form-checkbox:checked {
    @apply bg-btn-primary border-btn-primary;
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>');
  }
  .form-checkbox:indeterminate {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h8"/></svg>');
  }
  .form-radio {
    border-radius: 100%;
  }
  .form-radio:checked {
    @apply bg-btn-primary border-btn-primary;
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="3"/></svg>');
  }
  .form-file {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
  }
  .form-file:focus-visible {
    outline: 1px solid ButtonText, 1px auto -webkit-focus-ring-color;
  }
}
@layer components {
  .field {
    @apply mb-4;
  }
  .field > label {
    @apply text-gray-800 font-bold;
  }
  .field label[for] {
    @apply cursor-pointer;
  }
  .field-h {
    @apply flex gap-2 items-baseline max-w-std;
  }
  @screen max-sm {
    .field-h {
      @apply block;
    }
  }
  .field-h > label:first-child, .field-h > .field-spacer:first-child {
    flex-basis: theme("spacing.40");
    flex-shrink: 0;
  }
  @screen lg {
    .field-h > label:first-child, .field-h > .field-spacer:first-child {
      flex-basis: theme("spacing.60");
    }
  }
  .field-h--compact {
    @apply flex gap-2 items-baseline max-w-std;
  }
  .field-h--compact > label:first-child, .field-h--compact > .field-spacer:first-child {
    flex-shrink: 1;
  }
  .field-h--compact > div {
    flex-grow: 0;
    @apply min-w-32 ml-auto;
  }
  .field-h--compact > div > input, .field-h--compact > div > select {
    flex-grow: 0;
  }
  .field-err {
    @apply box-content p-2 -mx-2 rounded bg-red-100;
  }
  .field-err .form-input,
  .field-err .form-textarea,
  .field-err .form-select {
    @apply ring-red-700;
  }
}
@layer utilities {
  .layout-topbar {
    @apply flex-shrink-0;
    @apply h-topnav;
  }
  .layout {
    @apply min-h-screen grid;
    @apply max-w-layout;
    grid-template-columns: theme("spacing.col-1") theme("spacing.col-5") 1fr;
  }
  @screen max-md {
    .layout {
      grid-template-columns: theme("spacing.col-1") 15rem 1fr;
    }
  }
  @screen max-sm {
    .layout {
      @apply flex flex-col;
    }
  }
  @media print {
    .layout {
      @apply block;
    }
  }
  .layout--bookmark {
    grid-template-columns: theme("spacing.col-1") auto;
  }
  .layout-topnav {
    @apply flex flex-col flex-shrink-0 gap-2 items-center;
    @apply box-border py-2 w-col-1 h-screen;
    @apply sticky top-0 z-40;
    @apply bg-gray-200 text-black;
  }
  @screen max-sm {
    .layout-topnav {
      @apply flex-row w-full h-topnav px-2;
      @apply translate-y-0 transition-transform delay-100;
    }
    body.scrolled-down .layout-topnav {
      @apply -translate-y-topnav;
    }
  }
  @media print {
    .layout-topnav {
      @apply hidden;
    }
  }
  .layout-topnav .logo {
    @apply flex items-center justify-center;
    @apply layout-topbar sm:-mt-2;
  }
  @screen max-sm {
    .layout-topnav .logo {
      @apply h-auto;
    }
    .layout-topnav .logo svg {
      @apply w-9 h-9;
    }
  }
  .layout-topnav .mainmenu {
    @apply flex-1 overflow-y-auto;
    @apply flex flex-col items-center gap-2;
  }
  @screen max-sm {
    .layout-topnav .mainmenu {
      @apply flex-row;
    }
  }
  .layout-topnav menu {
    @apply flex flex-col gap-3;
  }
  @screen max-sm {
    .layout-topnav menu {
      @apply flex-row gap-2;
    }
  }
  .layout-topnav menu a,
  .layout-topnav menu button,
  .layout-topnav button.sidemenu--button {
    @apply block;
    @apply rounded;
    @apply p-3;
    @apply text-gray-800 bg-gray-dark bg-opacity-5;
    @apply hf:bg-opacity-15;
    @apply data-current:bg-yellow-600 data-current:text-yellow-900;
  }
  :is(.dark .layout-topnav menu a,
  .dark .layout-topnav menu button,
  .dark .layout-topnav button.sidemenu--button) {
    @apply data-current:text-white data-current:bg-yellow-400;
  }
  @screen max-sm {
    .layout-topnav menu a,
    .layout-topnav menu button,
    .layout-topnav button.sidemenu--button {
      @apply p-2;
    }
  }
  .layout-topnav menu a .svgicon svg,
  .layout-topnav menu button .svgicon svg,
  .layout-topnav button.sidemenu--button .svgicon svg {
    @apply w-5 h-5;
  }
  @screen sm {
    .layout-topnav button[aria-controls=sidemenu] {
      @apply hidden;
    }
  }
  .a11y-nav {
    @apply absolute -top-80 z-50 w-full;
  }
  .a11y-nav a {
    @apply absolute left-0 right-0 transition-transform;
    @apply focus-visible:bg-gray-50/95;
    @apply p-4 text-center font-bold;
    @apply hf:top-80 hf:shadow-lg;
  }
  .sidemenu {
    @apply flex-shrink-0 sticky top-0 z-30 h-screen overflow-y-auto shadow-sidebar-l bg-gradient-to-r from-gray-50 to-gray-100 px-4;
  }
  :is(.dark .sidemenu) {
    @apply bg-app-bg from-gray-200/50 to-gray-200/50;
  }
  @screen max-sm {
    .sidemenu {
      @apply w-full to-gray-50;
    }
  }
  @media print {
    .sidemenu {
      @apply hidden;
    }
  }
  .sidemenu menu a {
    @apply hf:text-primary data-current:font-bold data-current:text-primary-dark;
    @apply flex items-center gap-1;
    @apply py-1 pl-1 mb-1.5 border-l-2 border-transparent hf:border-primary data-current:border-primary-dark;
  }
  @screen max-md {
    .sidemenu menu a {
      @apply mb-2 text-lg;
    }
  }
  .sidemenu menu a .svgicon,
  .sidemenu menu a .svgicon svg {
    @apply w-5 h-5;
  }
  .sidemenu menu a .count {
    @apply rounded-lg px-2 py-1.5 -my-1.5 min-w-8 text-center text-xs leading-none;
    @apply bg-gray-200 bg-opacity-25 text-gray-800 text-opacity-35;
  }
  .sidemenu menu a:hover .count, .sidemenu menu a:focus .count, .sidemenu menu a[data-current=true] .count {
    @apply font-normal text-gray-dark bg-opacity-100 text-opacity-100;
  }
  .sidemenu button.sidemenu--button {
    @apply fixed z-40 top-2 right-4;
    @apply p-1 border rounded text-gray-400;
    @apply cursor-pointer;
    @apply hf:text-gray-700;
  }
  @screen sm {
    .sidemenu button.sidemenu--button {
      @apply hidden;
    }
  }
  @screen max-sm {
    body.js .sidemenu {
      @apply fixed visible bottom-0 left-0 top-0 z-50;
      @apply transition-transform delay-100;
      @apply translate-x-0;
    }
  }
  @screen max-sm {
    body.js .sidemenu--hidden {
      @apply invisible -translate-x-[105%];
    }
  }
  @screen max-sm {
    body.no-js .sidemenu {
      @apply relative h-auto order-last;
    }
  }
  body.no-js button.sidemenu--button {
    @apply hidden;
  }
  .layout-content {
    container-name: layout-content;
    container-type: inline-size;
    @apply bg-transparent mx-6 my-8;
    @apply relative;
  }
}
@prose {
  line-height: 1.5;
}
@layer components {
  .prose-grid {
    background-image: linear-gradient(rgba(245, 152, 245, 0.3960784314) 1px, transparent 1px);
    background-size: 100% 1.5em;
  }
  .prose h1 {
    @flow-block;
    font-size: 1.8em;
  }
  .prose h2 {
    @flow-block;
    font-size: 1.6em;
  }
  .prose h3 {
    @flow-block;
    font-size: 1.4em;
  }
  .prose h4 {
    @flow-block;
    font-size: 1.2em;
  }
  .prose h5 {
    @flow-block;
    font-size: 1em;
  }
  .prose h6 {
    @flow-block;
    font-size: 0.9em;
  }
  .prose p,
  .prose blockquote,
  .prose address,
  .prose figure,
  .prose hr {
    @flow-block;
    padding: 0;
  }
  .prose strong,
  .prose time,
  .prose b {
    font-weight: bold;
  }
  .prose a,
  .prose a:visited {
    @apply text-primary;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: theme("colors.gray.400");
  }
  @media print {
    .prose a,
    .prose a:visited {
      color: #000;
      font-weight: 600;
      text-decoration: underline;
    }
  }
  .prose a:focus-visible,
  .prose a:hover,
  .prose a:active {
    @apply text-primary-dark;
    text-decoration: underline;
  }
  .prose h1 > a[href^="#"], .prose h1 > a[href^="#"]:visited,
  .prose h2 > a[href^="#"],
  .prose h2 > a[href^="#"]:visited,
  .prose h3 > a[href^="#"],
  .prose h3 > a[href^="#"]:visited,
  .prose h4 > a[href^="#"],
  .prose h4 > a[href^="#"]:visited,
  .prose h5 > a[href^="#"],
  .prose h5 > a[href^="#"]:visited,
  .prose h6 > a[href^="#"],
  .prose h6 > a[href^="#"]:visited {
    color: inherit;
    text-decoration: none;
  }
  .prose em,
  .prose dfn,
  .prose i {
    font-style: italic;
  }
  .prose sub,
  .prose sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  .prose sup {
    top: -0.5em;
  }
  .prose sub {
    bottom: -0.25em;
  }
  .prose small {
    font-size: 80%;
  }
  .prose blockquote {
    padding: 1.5em;
    padding: calc(var(--prose-lh) * 1em);
    background: rgba(0, 0, 0, 0.03);
    border-left: 5px solid theme("colors.gray.300");
  }
  .prose blockquote *:last-child {
    margin-bottom: 0;
  }
  .prose cite {
    font-style: italic;
  }
  .prose q:before {
    content: open-quote;
  }
  .prose q:after {
    content: close-quote;
  }
  .prose pre {
    @flow-block;
    padding: 1.6666666667em;
    padding: calc(var(--prose-lh) * 1em - 1px) calc(var(--prose-lh) * 1em);
    font-size: 0.9em;
    border: 1px solid theme("colors.gray.300");
    background: rgba(0, 0, 0, 0.03);
    white-space: pre-wrap;
    overflow-x: auto;
  }
  .prose pre code {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: inherit;
  }
  .prose code,
  .prose kbd,
  .prose samp,
  .prose var {
    font-size: 0.875em;
    line-height: 1;
    padding: 1px 3px;
    border-radius: theme("borderRadius.sm");
    background-color: rgba(0, 0, 0, 0.04);
  }
  .prose mark {
    line-height: 1;
    padding: 1px 3px;
    background-color: theme("colors.yellow.300");
  }
  .prose img,
  .prose figure {
    max-width: 100%;
  }
  .prose img {
    @apply rounded;
    margin-bottom: 0.75em;
  }
  .prose figure {
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .prose figure img,
  .prose figure svg,
  .prose figure pre {
    display: block;
    margin: 0 auto;
  }
  .prose figure figcaption {
    @vertical-flow;
    font-size: 0.9em;
  }
  .prose figure *:last-child {
    margin-bottom: 0;
  }
  .prose ul,
  .prose ol,
  .prose dl {
    @flow-block;
    padding: 0;
  }
  .prose ul,
  .prose ol {
    list-style-position: outside;
    padding-left: 2em;
  }
  .prose ul {
    list-style-type: disc;
  }
  .prose ul > li::marker {
    font-size: 0.9em;
  }
  .prose ol {
    list-style-type: decimal;
  }
  .prose ol > li::marker {
    font-size: 0.75em;
    font-weight: 600;
  }
  .prose li p,
  .prose li ul,
  .prose li ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  .prose li img {
    display: inline-block;
  }
  .prose dl dt {
    font-weight: bold;
  }
  .prose dl dd {
    margin: 0 0 0 1.5em;
    padding: 0;
  }
  .prose table {
    @flow-block;
    margin-top: -2px;
    display: flow-root;
    overflow-x: auto;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .prose table caption,
  .prose table tbody,
  .prose table thead,
  .prose table tfoot {
    max-width: 100%;
  }
  .prose caption {
    color: theme("colors.gray.800");
    font-style: italic;
    margin-bottom: 0;
  }
  .prose td,
  .prose th {
    @vertical-flow;
    padding: 0.375em;
    padding: calc(var(--prose-line-height) / 4 * 1em - 0.5px);
    vertical-align: top;
    min-width: 2em;
    text-align: left;
    border: 1px solid theme("colors.gray.400");
  }
  .prose th {
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.03);
  }
  .prose thead tr:last-child th {
    border-bottom-color: theme("colors.gray.700");
  }
  .prose tfoot td,
  .prose tfoot th {
    font-style: italic;
  }
  @media print {
    .prose img {
      max-height: 50vh;
      width: auto;
      display: inline;
    }
    .prose figure {
      display: block;
      text-align: center;
    }
    .prose figure img,
    .prose figure svg {
      display: inline;
    }
    .prose figcaption {
      text-align: left;
    }
    .prose blockquote {
      text-align: left;
      background: none;
      padding-block: 0;
    }
    .prose img,
    .prose blockquote,
    .prose figure,
    .prose p:has(img) {
      break-inside: avoid;
      break-before: auto;
      break-after: auto;
    }
  }
}
@layer components {
  .video-player {
    position: relative;
    margin: 0 auto;
    max-height: 80dvh;
  }
  .video-player img,
  .video-player iframe,
  .video-player .play-button {
    position: absolute;
    display: block;
  }
  .video-player img,
  .video-player iframe {
    @apply rounded-md bg-black;
    width: 100%;
    height: 100%;
  }
  .video-player .play-button {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
  }
  .video-player .play-button span {
    display: block;
    width: 90px;
    height: 90px;
    background-color: #000;
    opacity: 0.75;
    border-radius: 100%;
    position: absolute;
    left: 50%;
  }
  .video-player .play-button span:before {
    position: absolute;
    z-index: 2;
    content: "";
    line-height: 0;
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: transparent transparent transparent #fff;
  }
  .video-player .play-button span, .video-player .play-button span:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .video-player .play-button:hover, .video-player .play-button:focus-visible {
    background-color: transparent;
    @apply ring-0;
  }
  .video-player .play-button:hover span, .video-player .play-button:focus-visible span {
    opacity: 1;
    @apply ring-4;
    @apply ring-yellow-500;
  }
  @media print {
    .video-player .play-button {
      display: none;
    }
  }
}
@layer components {
  details.menu > ul {
    @apply absolute z-30;
    @apply w-[max-content];
    @apply shadow-lg bg-gray-50 border border-gray-300 rounded-md;
    @apply py-1;
  }
  details.menu > ul > li {
    @apply block;
  }
  details.menu > ul > li a,
  details.menu > ul > li button {
    @apply block w-full text-left px-4 py-2;
    @apply hf:bg-gray-200;
  }
  details.menu > ul > li[aria-current] a, details.menu > ul > li[aria-current] button {
    @apply text-gray-900 font-semibold;
  }
  details.menu > ul > li[role=separator] {
    @apply border-t border-t-gray-300;
    @apply my-1;
  }
}
.paginator {
  container-name: paginator;
  container-type: inline-size;
  @apply my-4 flex items-center justify-center;
}

.paginator--basic {
  @apply hidden flex-1 justify-between;
}

@container paginator (width <= 540px) {
  .paginator--basic {
    @apply flex;
  }
}
.paginator--basic a {
  @apply px-4 py-2 border border-gray-300 rounded-md hf:text-primary;
}

.paginator--extended {
  @apply flex items-center justify-center flex-shrink divide-x;
  @apply border border-gray-300 rounded-md;
  @apply overflow-hidden;
}

@container paginator (width <= 540px) {
  .paginator--extended {
    @apply hidden;
  }
}
.paginator--extended > a, .paginator--extended > span {
  @apply px-4 py-2;
}

.paginator--extended > a {
  @apply hf:bg-gray-200 hf:text-primary;
}

.paginator--extended span.paginator--current {
  @apply bg-gray-200 font-bold;
}

.paginator--extended span.paginator--hellip {
  @apply text-gray-600;
}

@layer components {
  .bookmark-container {
    @apply col-start-2 col-span-2;
    @apply flex;
  }
  @media print {
    body .bookmark-container {
      @apply block max-w-none w-full h-auto overflow-visible;
      @apply mb-8 border-b border-l-0;
    }
  }
  @screen max-lg {
    body.no-js .bookmark-container {
      @apply flex-col;
    }
  }
  .bookmark-content {
    container-name: bookmark-content;
    container-type: inline-size;
    @apply max-w-full;
    @apply grow order-first;
    @apply px-6 pb-8;
    @apply bg-transparent;
  }
  .bookmark-content .bookmark-header,
  .bookmark-content .bookmark-article {
    margin-left: auto;
    margin-right: auto;
  }
  @screen lg {
    .bookmark-content {
      @apply min-h-screen;
    }
  }
  @screen max-lg {
    .bookmark-content {
      @apply px-4 grow;
    }
  }
  @media print {
    .bookmark-content {
      @apply overflow-visible;
      @apply max-w-none;
      @apply px-0;
    }
  }
  .bookmark-content rd-annotation {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  @media print {
    .bookmark-content .bookmark-article {
      columns: 2;
      column-gap: 2rem;
      orphans: 5;
      widows: 5;
      height: 100%;
      column-fill: auto;
    }
    .bookmark-content .bookmark-article img {
      width: auto;
      display: inline;
    }
    .bookmark-content .bookmark-article img {
      break-inside: avoid;
      break-before: auto;
      break-after: auto;
    }
  }
  @media print {
    .bookmark-content .bookmark-article .prose p,
    .bookmark-content .bookmark-article .prose li,
    .bookmark-content .bookmark-article .prose dd {
      text-align: justify;
    }
  }
  .bookmark-topbar {
    @apply sticky top-0 z-20 h-topnav;
    @apply flex flex-col;
    @apply border-b border-b-gray-200;
    @apply bg-app-bg bg-opacity-95 text-gray-400 hfw:text-gray-600;
    @apply print:hidden;
  }
  @screen max-sm {
    .bookmark-topbar {
      @apply top-topnav transition-all delay-100;
    }
    body.scrolled-down .bookmark-topbar {
      @apply -top-topnav;
      top: calc(0 - theme("space.topnav") + theme("space[1]"));
    }
  }
  .bookmark-topbar progress {
    -webkit-appearance: none;
    appearance: none;
    @apply w-full h-0.5 bg-transparent;
  }
  .bookmark-topbar progress::-webkit-progress-bar {
    @apply bg-transparent;
  }
  .bookmark-topbar progress::-moz-progress-bar {
    @apply bg-gradient-to-r from-blue-700 to-blue-500;
  }
  .bookmark-topbar progress::-webkit-progress-value {
    @apply bg-gradient-to-r from-blue-700 to-blue-500;
  }
  .bookmark-sidebar {
    @apply w-col-6 h-screen sticky overflow-y-auto z-30;
    @apply border-l border-l-gray-200;
  }
  @screen lg {
    .bookmark-sidebar {
      @apply top-0;
    }
  }
  @screen max-lg {
    .bookmark-sidebar {
      @apply fixed top-0 right-0 w-full max-w-col-10 z-50;
      @apply bg-app-bg shadow-panel-r;
    }
  }
  @media print {
    .bookmark-sidebar {
      @apply static max-w-none w-full h-auto overflow-visible;
      @apply mb-8 border-b border-l-0;
    }
  }
  @screen max-lg {
    body.no-js .bookmark-sidebar {
      @apply block static shadow-none w-auto max-w-none h-auto;
    }
  }
}
.bookmark-display--width-regular {
  max-width: 48rem;
}

.bookmark-display--width-wide {
  max-width: 56rem;
}

@container bookmark-content (width < 56rem) {
  .bookmark-display--width-wide {
    max-width: 52rem;
  }
}
@container bookmark-content (width < 54rem) {
  .bookmark-display--width-wide {
    max-width: 50rem;
  }
}
@container bookmark-content (width < 48rem) {
  .bookmark-display--width-regular, .bookmark-display--width-wide {
    max-width: 42rem;
  }
}
@container bookmark-content (width < 44rem) {
  .bookmark-display--width-regular, .bookmark-display--width-wide {
    max-width: 40rem;
  }
}
@container bookmark-content (width < 42rem) {
  .bookmark-display--width-regular, .bookmark-display--width-wide {
    max-width: 37rem;
  }
}
.bookmark-display--width-full {
  max-width: 100%;
}

.bookmark-display--size-1 {
  @apply text-sm;
}

.bookmark-display--size-2 {
  @apply text-base;
}

.bookmark-display--size-3 {
  @apply text-lg;
}

.bookmark-display--size-4 {
  @apply text-xl;
}

.bookmark-display--size-5 {
  @apply text-2xl;
}

.bookmark-display--size-6 {
  @apply text-3xl;
}

.bookmark-display--leading-1 {
  @apply leading-[1.1];
}

.bookmark-display--leading-2 {
  @apply leading-tight;
}

.bookmark-display--leading-3 {
  @apply leading-normal;
}

.bookmark-display--leading-4 {
  @apply leading-relaxed;
}

.bookmark-display--leading-5 {
  @apply leading-loose;
}

.bookmark-display--leading-6 {
  @apply leading-[2.25];
}

.bookmark-display--font-lora {
  @apply font-lora underline-offset-[3px];
}

.bookmark-display--font-public-sans {
  @apply font-public-sans;
}

.bookmark-display--font-merriweather {
  @apply font-merriweather underline-offset-[3px];
}

.bookmark-display--font-inter {
  @apply font-inter;
}

.bookmark-display--font-plex-serif {
  @apply font-plex-serif underline-offset-[3px];
}

.bookmark-display--font-literata {
  @apply font-literata underline-offset-[3px];
}

.bookmark-display--font-luciole {
  @apply font-luciole decoration-1;
}

.bookmark-display--font-atkinson-hyperlegible {
  @apply font-atkinson-hyperlegible underline-offset-[3px];
}

.bookmark-display--font-jetbrains-mono {
  @apply font-jetbrains-mono;
}

.bookmark-display--align-justify p,
.bookmark-display--align-justify li,
.bookmark-display--align-justify dd {
  text-align: justify;
}

.bookmark-display--hyphen-auto p,
.bookmark-display--hyphen-auto li,
.bookmark-display--hyphen-auto dd {
  hyphens: auto;
}

@layer components {
  .prose.docs p,
  .prose.docs li,
  .prose.docs dd {
    hyphens: none;
  }
  .prose.docs a {
    font-weight: 600;
  }
  .prose.docs h2 {
    @flow-block;
    font-size: 1.6em;
    font-weight: 500;
    color: rgb(var(--color-gray-700));
    border-bottom: 1px solid rgb(var(--color-gray-200));
    position: relative;
    padding-bottom: 5px;
    top: -5px;
  }
  .prose.docs h3 {
    color: rgb(var(--color-yellow-700));
  }
  .prose.docs figure img {
    border: 1px solid rgb(var(--color-gray-200));
    padding: 0.25rem;
  }
  .prose.docs figure figcaption {
    text-align: center;
    margin-top: 0.25rem;
  }
}
@layer components {
  rapi-doc {
    --font-regular: theme("fontFamily.sans");
    --font-mono: theme("fontFamily.mono");
    --focus-shadow: 0 0 0 1px transparent, 0 0 0 3px var(rgb(--color-yellow-600));
    --bg: rgb(var(--color-app-bg));
    --bg2: rgb(var(--color-gray-100));
    --bg3: rgb(var(--color-gray-100));
    --light-bg: rgb(var(--color-gray-100) / 0.8);
    --fg: rgb(var(--color-app-fg));
    --fg2: rgb(var(--color-gray-fg) / 0.9);
    --fg3: rgb(var(--color-app-fg) / 0.8);
    --light-fg: rgb(var(--color-app-fg) / 0.7);
    --selection-bg: rgb(var(--color-btn-primary));
    --selection-fg: rgb(var(--color-btn-primary-text));
    --overlay-bg: rgb(var(--color-gray-dark) / 0.4);
    --border-color: rgb(var(--color-gray-300));
    --light-border-color: rgb(var(--color-gray-300));
    --code-border-color: transparent;
    --input-bg: rgb(var(--color-gray-50));
    --placeholder-color: rgb(var(--color-gray-600));
    --hover-color: rgb(var(--color-gray-100));
    --red: rgb(var(--color-red-600));
    --light-red: rgb(var(--color-red-100));
    --pink: #990055;
    --light-pink: #ffb2b2;
    --green: rgb(var(--color-green-700));
    --light-green: rgb(var(--color-green-200));
    --blue: rgb(var(--color-blue-600));
    --light-blue: rgb(var(--color-blue-200));
    --orange: rgb(var(--color-yellow-800));
    --light-orange: rgb(var(--color-yellow-400));
    --yellow: rgb(var(--color-yellow-600));
    --light-yellow: rgb(var(--color-yellow-200));
    --purple: #786ff1;
    --brown: rgb(var(--color-yellow-800));
    --header-bg: rgb(var(--color-gray-800));
    --header-fg: rgb(var(--color-gray-50));
    --header-color-darker: rgb(var(--color-gray-800));
    --header-color-border: rgb(var(--color-gray-700));
    --nav-bg-color: rgb(var(--color-app-bg));
    --nav-text-color: rgb(var(--color-gray-800));
    --nav-hover-bg-color: rgb(var(--color-gray-200) / 0.6);
    --nav-hover-text-color: rgb(var(--color-app-fg));
    --nav-accent-color: rgb(var(--color-primary));
    --nav-accent-text-color: rgb(var(--color-app-fg));
    --nav-get-color: rgb(var(--color-blue-600));
    --nav-put-color: rgb(var(--color-yellow-500));
    --nav-post-color: rgb(var(--color-green-600));
    --nav-delete-color: rgb(var(--color-red-600));
    --nav-head-color: rgb(var(--color-yellow-600));
    --primary-color: rgb(var(--color-primary));
    --primary-color-invert: rgb(var(--color-gray-light));
    --primary-color-trans: rgb(var(--color-blue-200) / 0.4);
    --code-bg: rgb(var(--color-gray-100));
    --code-fg: rgb(var(--color-grey-900));
    --inline-code-fg: rgb(var(--color-red-700));
    --code-property-color: rgb(var(--color-red-600));
    --code-keyword-color: rgb(var(--color-blue-600));
    --code-operator-color: rgb(var(--color-yellow-700));
  }
  :is(.dark rapi-doc) {
    --pink: #ffb2b2;
    --light-pink: #ffb2b2;
    --purple: #a8a2f7;
  }
  rapi-doc::part(section-navbar) {
    background: linear-gradient(to right, rgb(var(--color-gray-50)), rgb(var(--color-gray-100)));
    box-shadow: theme("boxShadow.sidebar-l");
    width: theme("spacing.col-5");
  }
  rapi-doc::part(section-navbar-operations-top) {
    display: none;
  }
  rapi-doc::part(section-navbar-scroll) {
    padding: 0 theme("padding.4");
  }
  rapi-doc::part(section-navbar-item) {
    border-left-width: 2px;
    font-weight: theme("fontWeight.medium");
  }
  rapi-doc::part(section-navbar-tag) {
    padding: theme("spacing.3") theme("spacing.2");
  }
  rapi-doc::part(section-navbar-active-item) {
    border-left-color: rgb(var(--color-primary-dark));
    border-radius: 0;
  }
  rapi-doc::part(section-operations-in-tag), rapi-doc::part(section-overview), rapi-doc::part(section-auth) {
    padding: 0;
  }
  rapi-doc::part(section-main-content) {
    padding: 2rem 1.5rem;
    /* margin-left: 0 1.5rem */
  }
  rapi-doc::part(label-operation-method) {
    font-weight: bolder;
  }
  rapi-doc::part(label-operation-path) {
    font-weight: bold;
    font-size: 1.2em;
  }
  rapi-doc::part(btn) {
    box-shadow: none;
    border-radius: theme("borderRadius.DEFAULT");
  }
  rapi-doc::part(textbox) {
    border-radius: theme("borderRadius.DEFAULT");
  }
}