// SPDX-FileCopyrightText: © 2024 Olivier Meunier <olivier@neokraft.net>
//
// SPDX-License-Identifier: AGPL-3.0-only

// JS enabled CSS rules
body.js {
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

@layer base {
  svg {
    fill: currentColor;
  }

  :focus-visible {
    @apply outline-none;
    @apply ring-2 ring-offset-2;
    @apply ring-yellow-500 bg-yellow-100 ring-offset-yellow-100;

    :is(.dark &) {
      @apply ring-yellow-400 bg-yellow-100 ring-offset-yellow-100;
    }
  }

  ::selection {
    @apply bg-blue-700 text-gray-light;
  }

  button {
    * {
      pointer-events: none;
    }
  }

  details > summary {
    @apply cursor-pointer;

    &:not(.with-marker) {
      @apply list-none;

      &::-webkit-details-marker {
        display: none;
      }
    }
  }

  progress {
    -webkit-appearance: none;
    appearance: none;
    @apply rounded-full bg-gray-300;

    &::-webkit-progress-bar {
      @apply rounded-full bg-gray-300;
    }

    &::-moz-progress-bar {
      @apply rounded-full bg-primary;
    }

    &::-webkit-progress-value {
      @apply rounded-full bg-primary;
    }
  }
}

// Scrollbar
@layer base {
  * {
    scrollbar-color: rgb(var(--color-gray-400)) rgb(var(--color-gray-100));
    scrollbar-width: auto;
  }

  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    width: 4px;
    background: rgb(var(--color-gray-100));
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(var(--color-gray-400));
    width: 2px;
    border-radius: 3px;
  }
}

// Print
@layer base {
  @media print {
    html {
      font-size: 8pt;
    }
  }
}

// Password inputs
@layer base {
  /* autoprefixer: off */
  @supports (contain: paint) and (not (-moz-appearance: none)) {
    input[type="password"]:not(:placeholder-shown) {
      font-family: pass, sans-serif;
      letter-spacing: 1px;
    }
  }
}

// Generic utilities
@layer components {
  .link {
    @apply text-primary hf:text-primary-dark;

    @media print {
      @apply text-black underline font-semibold;
    }
  }

  .outline-std {
    @apply outline-1 outline-dotted outline-current;
  }

  .svgicon {
    display: inline;
    line-height: 1;
    position: relative;
    height: 1em;
    top: -0.09em;

    svg {
      height: 1em;
    }
  }

  .title {
    @apply mb-4 font-semibold text-gray-700;
  }

  .wrap-anywhere {
    overflow-wrap: anywhere;
  }
}

// Annotation styles
@layer components {
  rd-annotation,
  .rd-annotation {
    background-color: rgb(var(--annotation-bg) / var(--tw-bg-opacity, 1));
    border-color: var(--annotation-border);

    &[data-annotation-color="yellow"] {
      --annotation-bg: var(--color-hl-yellow);
      --annotation-border: rgb(var(--color-hl-yellow-dark));
    }

    &[data-annotation-color="red"] {
      --annotation-bg: var(--color-hl-red);
      --annotation-border: rgb(var(--color-hl-red-dark));
    }

    &[data-annotation-color="blue"] {
      --annotation-bg: var(--color-hl-blue);
      --annotation-border: rgb(var(--color-hl-blue-dark));
    }

    &[data-annotation-color="green"] {
      --annotation-bg: var(--color-hl-green);
      --annotation-border: rgb(var(--color-hl-green-dark));
    }
  }

  // Annotation button styles
  button.rd-annotation {
    --annotation-border: rgb(var(--color-white) / 0.8);

    &[value="yellow"] {
      --annotation-bg: var(--color-yellow-700);
    }
    &[value="red"] {
      --annotation-bg: var(--color-red-700);
    }
    &[value="blue"] {
      --annotation-bg: var(--color-blue-700);
    }
    &[value="green"] {
      --annotation-bg: var(--color-green-700);
    }
  }
}
